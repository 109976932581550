@import url(https://fonts.googleapis.com/css?family=Baskervville|Dancing+Script|Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
  font-family: 'Baskervville', serif;
  font-family: 'Roboto Mono', monospace;
  font-family: 'Dancing Script', cursive;
  font-family: 'Montserrat', sans-serif;
*/

body {
  background-color: #FFF3E4;
}

.App {
  margin: 0 auto;
  text-align: center;
  width: 80%;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text {
  color: #666666;
}

.medieval {
  font-family: 'Baskervville', serif;
}

.cursive {
  font-family: 'Dancing Script', cursive;
}

.title {
  font-family: 'Roboto Mono', monospace;
}

.directives {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image {
  width: 100%;
}

main {
  margin-top: 5%;
  display: flex;
  justify-content: space-around;
}

section {
  width: 43%;
}

.line-below {
  padding: 0px 0px 2em 0px;
  border-bottom: solid #d9ccff 2px;
}

.descriptions {
  color: #696969;
  font-family: 'Montserrat', sans-serif;
}

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
}

